/* 13. Basic Styling with CSS */

/* Style the lines by removing the fill and applying a stroke */
.line {
  fill: none;
  stroke: #ffab00;
  stroke-width: 3;
}

.overlay {
  fill: none;
  pointer-events: all;
}

/* Style the dots by assigning a fill and stroke */
.dot {
  fill: #ffab00;
  stroke: #fff;
}

.focus circle {
  fill: none;
  stroke: steelblue;
}

.sidenav {
  width: 20% !important;
}

.no-data {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.break {
  height: 0;
  flex-basis: 100%;
}

.no-data {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-content {
  margin-left: 5%;
  margin-right: 5%;
}

@media (min-width: 914px) {
  .page-content {
    margin-left: 25%;
    margin-right: 5%;
  }
}
